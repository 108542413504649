import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as React from 'react';

import Hr from '../../Hr';
import Layout from '../../Layout';
import SEO from '../../SEO';
import styles from './styles.module.css';

// Customize markdown component
const mdxComponents = {
  hr: () => <Hr widthInPercent="100" verticalMargin="0.8rem" />,
};

const PostTemplate: React.FC = props => {
  const post = props.data.mdx;
  const isAboutPage = post.fields.slug.includes('/about');

  return (
    <Layout showTitle={true} isPostTemplate>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <article className={styles.Post}>
        {!isAboutPage && (
          <>
            <h1 className={styles.Title}>{post.frontmatter.title}</h1>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.8rem',
              }}
            >
              {post.frontmatter.tags.map(tag => (
                <span key={tag} className={styles.Tag}>
                  {tag}
                </span>
              ))}
              <time
                className={styles.Date}
                dateTime={post.frontmatter.date.replace(/\//g, '-')}
              >
                {post.frontmatter.date}
              </time>
            </div>
            <Hr />
          </>
        )}

        <MDXProvider components={mdxComponents}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </Layout>
  );
};

export const postQuery = graphql`
  query BlogPostByPath($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        tags
      }
    }
  }
`;

export default PostTemplate;
