import './styles.css';

import * as React from 'react';

type HrProps = {
  verticalMargin?: string | number;
  widthInPercent?: string | number;
} & React.HTMLAttributes<HTMLHRElement>;

const Hr: React.FC<HrProps> = ({
  verticalMargin,
  widthInPercent,
  ...otherProps
}) => {
  // Set defaults
  const width = widthInPercent !== void 0 ? `${widthInPercent}%` : `100%`;
  const margin =
    verticalMargin !== void 0 ? `${verticalMargin} auto` : `0.5rem auto`;
  return <hr style={{ margin, width }} {...otherProps} />;
};

export default Hr;
